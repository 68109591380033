function getValue(key: string): any {
  return JSON.parse(localStorage.getItem(key));
}

function setValue(key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function setTheme(value: any): void {
  setValue("theme", value);
}

function getTheme(): string {
  return getValue("theme");
}

export { getValue, setValue, setTheme, getTheme };
