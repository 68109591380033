const MyLocalStorage = require("./src/common/utils/MyLocalStorage");
// import MyLocalStorage from "./src/common/utils/MyLocalStorage";

// gatsby-browser.js
export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Access localStorage early
    const theme = MyLocalStorage.getTheme() || "dark";

    // Add attribute to <html> tag
    document.documentElement.setAttribute("data-theme", theme);
  }
};
